import React, { useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfile from './components/UserProfile/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer';
import TopArtists from './components/TopArtists';
import LineDivider from './components/UserProfile/utils/LineDivider';
import SocialNetwork from './components/SocialNetwork';

function App() {
  const [userRole, setUserRole] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState();
  const [userPicture, setUserPicture] = useState();
  const { isAuthenticated, isLoading } = useAuth0();

  const handleUserData = useCallback((userRole, isEmailVerified, isProfileCompleted, userPicture) => {
    setUserRole(userRole);
    setIsEmailVerified(isEmailVerified);
    setIsProfileCompleted(isProfileCompleted);
    setUserPicture(userPicture);
  }, []);

  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <Router>
      <div className="App">

        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                    isEmailVerified={isEmailVerified}
                    isProfileCompleted={isProfileCompleted}
                  />
                  <LineDivider />
                  <TopArtists />
                  <SocialNetwork />
                  <Footer />
                  
                </>
              ) : (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                  />
                  <LineDivider />
                  <TopArtists />
                  <SocialNetwork />
                  <Footer />
                </>
              )
            }
          />
          <Route
            path='auth/profile'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <UserProfile
                    userRole={userRole}
                    userPicture={userPicture}
                    isProfileCompleted={isProfileCompleted}
                  />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />
        </Routes>


      </div>
    </Router>

  );
}

export default App;
