import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react'; 

const theme = createTheme();

const root = ReactDOM.createRoot(document.getElementById('root'));

const onRedirectCallback = (appState) => {
  window.history.pushState(
    {},
    document.title,
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin,
  onRedirectCallback,
  cacheLocation: 'localstorage',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

root.render(
  <Auth0Provider {...providerConfig}
  >
    <React.StrictMode>

      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>

    </React.StrictMode>
  </Auth0Provider>
);

reportWebVitals();
