import React from 'react';
import { Box, Typography, Button  } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const BackToHome = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const errorDescription = urlParams.get('error_description') || 'Ocorreu um erro desconhecido.';

    return (
        <Box sx={{ textAlign: 'center', mt: 6 }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: 40, color: '#614ed9' }} />
            <Typography variant="h5" sx={{ mt: 2, color: '#000', fontWeight: 'normal' }}>
                {decodeURIComponent(errorDescription)}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
                onClick={() => window.location.href = '/'}
            >
                Voltar ao início
            </Button>
        </Box>
    );
};

export default BackToHome;
