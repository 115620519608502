import { Divider } from "@mui/material";

const LineDivider = () => {
    return (
        <>
            <Divider
            sx={{
                mt: 3,
                mb: 3
            }}
             />
        </>
    );
};

export default LineDivider;