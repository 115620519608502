import { useMediaQuery, Box, Grid, Typography, Card, CardMedia, CardContent, Chip, IconButton, Button } from "@mui/material";
import { useState, useEffect } from 'react';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TopArtists = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const [artists, setArtists] = useState([]);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const calculateWidth = (baseChValue) => {
        const chInPixels = 8;
        const baseWidthInPixels = baseChValue * chInPixels;
        const adjustedWidthInPixels = baseWidthInPixels - (screenWidth * 0.1);
        const adjustedWidthInCh = adjustedWidthInPixels / chInPixels;
        return `${adjustedWidthInCh}ch`;
    };

    useEffect(() => {
        const fetchUserArtistList = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuserlist`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch user artist list');
                }
                const result = await response.json();
                const filteredArtists = result.artists.filter(artist =>
                    artist.music_styles.length > 0 &&
                    artist.period.length > 0 &&
                    artist.artist_pictures.length > 0
                );

                setArtists(filteredArtists);
            } catch (error) {
                console.error('Error fetching user artist list:', error);
            }
        };
        fetchUserArtistList();
    }, [railwayAPIURL, apiAuthKey]);

    const getChipColor = (artistType) => {
        switch (artistType) {
            case 'band':
                return { bg: '#8C57FF', text: '#C6ACFF' };
            case 'musician':
                return { bg: '#16B1FF', text: '#0087C9' };
            case 'dj':
                return { bg: '#FFB400', text: '#9B6E00' };
            default:
                return { bg: '#CCCCCC', text: '#666666' };
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    const ArtistCard = ({ artist }) => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        const nextImage = () => {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex + 1) % artist.artist_pictures.length
            );
        };

        const prevImage = () => {
            setCurrentImageIndex((prevIndex) =>
                (prevIndex - 1 + artist.artist_pictures.length) % artist.artist_pictures.length
            );
        };

        return (
            <Card
                sx={{
                    width: isMobile ? '100%' : '320px',
                    maxWidth: '100%',
                }}
            >
                <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            mt: 1,
                            borderRadius: '5px',
                            overflow: 'hidden',
                            width: '95%',
                            maxWidth: 300,
                        }}
                    >
                        <CardMedia
                            sx={{
                                width: '100%',
                                height: 160,
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                            }}
                            image={artist.artist_pictures[currentImageIndex]}
                        />
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '-20px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            },
                            transition: 'background-color 0.3s',
                            height: '50px',
                            width: '40px',
                            display: 'flex',
                            alignItems: 'right',
                            justifyContent: 'right',
                            borderRadius: '5px',
                        }}
                    >
                        <IconButton
                            onClick={prevImage}
                            sx={{
                                padding: 0,
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                        >
                            <ArrowBackIosNewIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                        </IconButton>
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '-20px',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            },
                            '&:active': {
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            },
                            transition: 'background-color 0.3s',
                            height: '50px',
                            width: '40px',
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'left',
                            borderRadius: '5px',
                        }}
                    >
                        <IconButton
                            onClick={nextImage}
                            sx={{
                                padding: 0,
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                        >
                            <ArrowForwardIosIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                        </IconButton>
                    </Box>
                </Box>
                <CardContent >
                    <Box
                        onClick={() => {
                        }}
                        sx={{ cursor: 'pointer' }}
                    >

                        <Grid container spacing={20}>
                            <Grid item xs={6}>
                                <Chip
                                    label={
                                        artist.artist_type === 'band' ? 'banda' :
                                            artist.artist_type === 'musician' ? 'músico' :
                                                artist.artist_type === 'dj' ? 'dj' :
                                                    artist.artist_type
                                    }
                                    sx={{
                                        backgroundColor: getChipColor(artist.artist_type).bg,
                                        color: getChipColor(artist.artist_type).text,
                                        fontWeight: 'bold',
                                        height: '24px',
                                        fontSize: '0.75rem',
                                        padding: '0 8px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '120px',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#2e263de6'
                                    }}
                                >
                                    {artist.rating.toFixed(1)}
                                    <StarIcon
                                        fontSize="medium"
                                        sx={{
                                            color: '#FFB400',
                                            verticalAlign: 'middle',
                                            marginBottom: '5px',
                                            ml: 1
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                mt: 1,
                                textAlign: 'left',
                                textJustify: 'inter-word',
                                color: '#2e263de6'
                            }}
                        >
                            {artist.artist_name}
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            sx={{
                                textAlign: 'left',
                                textJustify: 'inter-word',
                                color: '#2e263db3'
                            }}
                        >

                            {truncateText(artist.artist_description, 60)}
                        </Typography>
                        {/* <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            mb: 1,
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        {artist.music_styles.map((style, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                                <MusicNoteOutlinedIcon fontSize="small" />
                                <Chip
                                    label={style}
                                    size="small"
                                    sx={{ backgroundColor: '#f0f0f0', color: '#333' }}
                                />
                            </Box>
                        ))}
                    </Box>
                    <Typography
                        variant="body2"
                        gutterBottom
                        sx={{
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <PeopleAltOutlinedIcon
                            fontSize="small"
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                            }}
                        />
                        {artist.members_number} membros
                    </Typography>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
                        <AccessTimeOutlinedIcon
                            fontSize="small"
                        />
                        {artist.period.map((period, index) => (
                            <>
                                <Chip
                                    key={index}
                                    label={period}
                                    size="small"
                                    sx={{ backgroundColor: '#f0f0f0', color: '#333' }}
                                />
                            </>
                        ))}
                    </Box> */}
                        <Grid container alignItems="center">
                            <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#2e263de6',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    <LocalOfferOutlinedIcon
                                        fontSize="small"
                                        sx={{
                                            verticalAlign: 'middle',
                                            mr: 1,
                                            color: '#614ED9'
                                        }}
                                    />
                                    R$ {artist.price}
                                </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Button
                                    variant="text"
                                    size="small"
                                    sx={{
                                        padding: '6px 12px',
                                        minWidth: 'auto',
                                        color: '#614ED9'
                                    }}
                                >
                                    Ver mais
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return (
        <Box sx={{
            maxHeight: isMobile ? '380px' : '350px',
            paddingBottom: isMobile ? `${artists.length * 320}px` : `${artists.length * 100}px`,
            mt: 2,
            ml: isMobile ? 5 : 10,
            mr: isMobile ? 5 : 10
        }}
        >
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': {
                        width: isMobile ? calculateWidth(35) : '55ch',
                        mt: 3,
                    },
                    paddingBottom: '100px',
                }}
                noValidate
                autoComplete="off"
            >

                <Box sx={{ mb: 3 }}>
                    <Typography
                        variant={isMobile ? "h5" : "h5"}
                        fontWeight="bold"
                        sx={{
                            textAlign: isMobile ? 'center' : 'left',
                            color: '#2e263de6'
                        }}
                    >
                        Top 5 artistas
                    </Typography>
                    <Typography
                        variant={"subtitle1"}
                        sx={{
                            textAlign: isMobile ? 'center' : 'left',
                            color: '#2e263db3'
                        }}
                    >
                        Os artistas mais bem ranqueados
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    {artists.map((artist, index) => (
                        <Grid item xs={12} sm={isMobile ? 12 : 4} key={index}>
                            <ArtistCard artist={artist} />
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </Box>
    );
};

export default TopArtists;