import React, { useState } from 'react';
import { Box, Button, TextField, MenuItem, Stack, useMediaQuery, ListItemIcon, Typography, Paper, Tooltip, IconButton, ClickAwayListener } from '@mui/material';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import AlbumOutlinedIcon from '@mui/icons-material/AlbumOutlined';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SearchFilters = () => {
  const [selected, setSelected] = useState("banda");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        justifyContent: 'center',
        paddingTop: '10px',
        border: '1px solid #D3D3D3',
        borderRadius: '8px',
        padding: isMobile ? '36px' : '20px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundImage: 'url(/static/images/wave_pattern.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: -2,
        },
        zIndex: 0,
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        sx={{
          flexWrap: 'wrap',
          gap: 1,
          alignItems: isMobile ? 'center' : 'center',
          justifyContent: isMobile ? 'center' : 'center',
          width: '90%',
        }}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          sx={{
            mb: 4,
            textAlign: 'left',
            justifyContent: isMobile ? 'center' : 'center',
            alignItems: isMobile ? 'center' : 'center',
            width: isMobile ? '80%' : '100%',
          }}
        >
          <Stack
            direction={'row'}
          >
            <Typography variant="h6" gutterBottom sx={{ color: 'white', fontWeight: 'bold' }}>
              O que você está procurando?
            </Typography>

            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Selecione o perfil artístico, região, estilo musical, perído e data para ter uma melhor resultado."

              >
                <IconButton
                  size="small"
                  sx={{
                    ml: isMobile ? 1 : 2,
                    mt: isMobile ? 5 : 1,
                    mb: isMobile ? 5 : 1,
                    backgroundColor: 'white',
                    padding: '1px',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                  onClick={handleTooltipOpen}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>

          </Stack>


          <Button
            onClick={() => handleClick("banda")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: selected === "banda" ? 'white' : '#16086E',
              color: selected === "banda" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: selected === "banda" ? '#808080' : 'white',
              },
            }}
            aria-label="banda"
          >
            <ListItemIcon>
              <Groups2OutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              Banda
            </Typography>
          </Button>

          <Button
            onClick={() => handleClick("musico")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: selected === "musico" ? 'white' : '#16086E',
              color: selected === "musico" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: selected === "musico" ? '#808080' : 'white',
              },
            }}
            aria-label="musico"
          >
            <ListItemIcon>
              <MicExternalOnOutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              Músico
            </Typography>
          </Button>

          <Button
            onClick={() => handleClick("dj")}
            sx={{
              minWidth: isMobile ? '100%' : 120,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              border: '1px solid white',
              backgroundColor: selected === "dj" ? 'white' : '#16086E',
              color: selected === "dj" ? '#2812B3' : 'white',
              borderRadius: '12px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white',
                color: '#2812B3',
                '& .MuiListItemIcon-root': {
                  color: '#808080',
                },
              },
              '& .MuiListItemIcon-root': {
                color: selected === "dj" ? '#808080' : 'white',
              },
            }}
            aria-label="dj"
          >
            <ListItemIcon>
              <AlbumOutlinedIcon fontSize="medium" sx={{ color: 'inherit' }} />
            </ListItemIcon>
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'inherit', textTransform: 'none' }}>
              DJ
            </Typography>
          </Button>



        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          sx={{
            flexWrap: 'wrap',
            gap: 1,
            alignItems: isMobile ? 'center' : 'center',
            justifyContent: isMobile ? 'center' : 'center',
            width: '100%',
          }}
        >
          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Região"
              defaultValue="São Paulo, Zone IV"
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              <MenuItem value="São Paulo, Zone IV">São Paulo, Zone IV</MenuItem>
            </TextField>
          </Paper>

          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Estilo Musical"
              defaultValue="Trap/Funk"
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              <MenuItem value="Trap/Funk">Trap/Funk</MenuItem>
            </TextField>
          </Paper>

          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              select
              label="Período"
              defaultValue="Tarde"
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
            >
              <MenuItem value="Tarde">Tarde</MenuItem>
            </TextField>
          </Paper>

          <Paper
            elevation={1}
            sx={{
              padding: '8px',
              borderRadius: '8px',
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2812B3',
                },
                '&.Mui-focused': {
                  color: '#2812B3',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#2812B3',
              },
              '& .MuiInputBase-input': {
                color: '#2812B3',
              },
            }}
          >
            <TextField
              label="Data do evento"
              type="date"
              defaultValue="2023-10-06"
              sx={{
                width: '100%',
                backgroundColor: 'transparent',
                '& .MuiInputBase-input': {
                  color: '#2812B3',
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Paper>

          <Button
            variant="contained"
            sx={{
              minWidth: '120px',
              backgroundColor: '#FFCA00',
              color: 'white',
              height: '50px',
              '&:hover': {
                backgroundColor: '#FFCA00',
              },
              width: isMobile ? '100%' : 'auto',
              maxWidth: '300px',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              top: '8px',
              fontWeight: 'bold',
            }}
            startIcon={<SearchIcon />}
          >
            Pesquisar
          </Button>




        </Stack>
      </Stack>
    </Box>
  );
};

export default SearchFilters;
