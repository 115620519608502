import { useMediaQuery, Box, Grid, Typography } from "@mui/material";

const TopArtists = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));


    return (
        <>
            <Box sx={{
                maxHeight: '300px',
                paddingBottom: '4px',
                backgroundColor: '#F5F5F5',
            }}>
                <Grid container spacing={2}
                >
                    <Grid item xs={12} sm={isMobile ? 12 : 6}>
                        <Box
                            sx={{
                                textAlign: 'justify',
                                color: '#2e263de6',
                                mt: 6,
                                mb: 2,
                                ml: isMobile ? 1 : 6,
                                mr: isMobile ? 1 : 6
                            }}
                        >
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                CONEXXAMUSIC.COM - Copyright 2024. Todos os direitos reservados.
                            </Typography>

                        </Box>
                    </Grid>


                    <Grid item xs={12} sm={isMobile ? 12 : 6}>
                        <Box
                            sx={{
                                textAlign: 'justify',
                                color: '#2e263de6',
                                mt: 4,
                                mb: 6,
                                ml: isMobile ? 1 : 6,
                                mr: isMobile ? 1 : 6
                            }}
                        >
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                A Conexxa é uma plataforma que conecta contratantes, como donos de estabelecimentos, a artistas — bandas, músicos e DJs — facilitando o processo de contratação e negociação para ambos.
                            </Typography>
                        </Box>
                    </Grid>


                </Grid>
            </Box>

        </>
    );
};

export default TopArtists;
